import Cookies from 'js-cookie'

const TOKEN_KEY = 'login-token';
const OPENID_KEY = 'wx-openid';

export function getToken() {
  return Cookies.get(TOKEN_KEY)
}

export function setToken(token) {
  return Cookies.set(TOKEN_KEY, token)
}

export function removeToken() {
  return Cookies.remove(TOKEN_KEY)
}

export function getOpenId() {
  return localStorage.getItem(OPENID_KEY);
  // return "on78D1kzfh5g5GXndhmecCSB3ARY";
  // return "on78D1ow5fbT4YvpXwHSmFPOLLeU";
  // return "on78D1no_Cyg1lSIxAB61YrzqyIk";
  // return "o44np0T1AghDvhU4C22NVcjJLLOc";
}

export function setOpenId(openId) {
  return localStorage.setItem(OPENID_KEY, openId)
}

export function clearCache() {
  try {
    Cookies.remove(TOKEN_KEY);
    localStorage.clear();
  } catch (err) {
    console.log(err);
  }
}